export default {
  confirm: "Confirm",
  cancel: "Prekliči",
  close: "Zapri",
  add: "Add",
  save: "Save",
  remove: "Remove",
  delete: "Izbriši",
  generate: "Generiraj",
  show_details: "Prikaži podrobnosti",
  open: "Odpri",
  edit: "Uredi",
  continue: "Nadaljuj",
  activate: "Aktiviraj",
  deactivate: "Deaktiviraj",
  components: {
    contact_info: {
      address: {
        title: "Address",
        remove_button: "Remove address",
        add_button: "Add address",
        address: "Address",
        postal: "Postal",
        city: "City",
        country: "Country"
      },
      contact: {
        title: "Kontakt",
        remove_button: "Odstrani kontakt",
        add_button: "Dodaj kontakt",
        contact: "Kontakt",
        phone: "Telefonska številka",
        email: "Elektronski naslov",
        any: "Kontakt",
        type: "Tip",
        label: "Ime in priimek"
      }
    },
    multiple_entries_check: {
      label: "Želite vpisati še eno?",
      description: "Po potrditvi bo forma ostala odprta"
    },
    query_builder: {
      title: "Najdi {models} kjer ...",
      type: {
        string: "Tekst",
        number: "Številka",
        boolean: "Boolean",
        date: "Datum",
        enum: "Enum",
        relation: "Relacija"
      },
      comparison_operators: {
        string: {
          "=": "Je enako",
          "!=": "Ni enako",
          contains: "Vsebuje",
          starts_with: "Se začne z",
          ends_with: "Se konča z",
          is_empty: "Je prazen",
          is_not_empty: "Ni prazen"
        },
        number: {
          "=": "=",
          "!=": "≠",
          "<": "<",
          ">": ">",
          "<=": "≤",
          ">=": "≥",
          exists: "Obstaja",
          not_exists: "Ne obstaja"
        },
        boolean: {
          "=": "Je",
          "!=": "Ni"
        },
        date: {
          "=": "Je",
          "!=": "Ni",
          "<": "Je pred",
          ">": "Je za",
          "<=": "Je na ali pred",
          ">=": "Je na ali za",
          exists: "Obstaja",
          not_exists: "Ne obstaja"
        },
        enum: {
          "=": "Je",
          "!=": "Ni",
          is_empty: "Je prazen",
          is_not_empty: "Ni prazen"
        },
        relation_one: {
          exists: "Obstaja",
          not_exists: "Ne obstaja",
          exists_where: "Kjer obstaja...",
          not_exists_where: "Kjer ne obstaja..."
        },
        relation_many: {
          exists: "Obstaja",
          not_exists: "Ne obstaja",
          exists_where: "Kjer obstaja...",
          not_exists_where: "Kjer ne obstaja..."
        }
      },
      logical_operators: {
        and: "In",
        or: "Ali"
      },
      property_placeholder: "Izberite lastnost",
      boolean: {
        true: "Ja",
        false: "Ne"
      },
      date: {
        type: {
          today: "Danes",
          tomorrow: "Jutri",
          yesterday: "Včeraj",
          week_ago: "Pred enim tednom",
          week_from_now: "Čez en teden",
          month_ago: "Pred enim mesecem",
          month_from_now: "Čez en mesec",
          now: "Zdaj",
          hour_ago: "Pred eno uro",
          hour_from_now: "Čez eno uro",
          "12h_ago": "Pred 12 urami",
          "12h_from_now": "Čez 12 ur",
          this_year: "To leto",
          year_ago: "Pred enim letom",
          year_from_now: "Čez eno leto",
          date: "Točen datum",
          datetime: "Točen datum in ura",
          time: "Točna ura",
          month: "Točen mesec",
          year: "Točno leto"
        }
      },
      relation: {
        results_count: "Število najdenih rezultatov"
      },
      add_filter_button: "Dodaj filter",
      add_filter_group_button: "Dodaj skupino filtrov",
      actions: {
        duplicate: "Dupliciraj",
        turn_into_group: "Spremeni v skupino",
        release_group: "Razpusti skupino",
        remove: "Odstrani"
      }
    }
  },
  navigation: {
    views: {
      tasks: "Opravila",
      projects: "Projekti",
      testings: "Testiranja",
      "assessment-center": "Ocenjevalni center",
      measures: "Meritve",
      companies: "Podjetja",
      candidates: "Kandidati",
      employees: "Zaposleni v podjetjih",
      competences: "Kompetence",
      questionnaires: "Vprašalniki",
      consents: "Soglasja",
      contracts: "Pogodbe",
      offers: "Ponudbe",
      "focus-groups": "Fokusne skupine",
      "informative-offers": "Informativne ponudbe",
      feedback: "Povratne informacije",
      analytics: "HR analitika",
      finances: "Finance",
      chat: "Sporočila",
      administration: "Administracija",
      settings: "Nastavitve",
      logout: "Odjava",
      search_and_selection: "Iskanje in selekcija",
      rosl: "ROSL",
      crm: "CRM"
    }
  }
}
