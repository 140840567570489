export default {
  views: {
    list: {
      header: {
        title: "Pogodbe",
        search_placeholder: "Iskanje ...",
        add_button: "Nova pogodba"
      },
      table: {
        title: "Naziv",
        status: "Status",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        responsible: "Odgovorna oseba",
        active: "Aktivna",
        options: "Možnosti",
        visible: "Preklicana",
        type: "Področje",
        salary: "Honorar",
        service: "Tip storitve",
        umbrella: "Krovna"
      },
      salary_sum: "Honorar skupaj:"
    },
    edit: {
      title: "Nova pogodba",
      back_button: "Na listo pogodb",
      create_project: "Ustvari projekt",
      create_from_offer: "Ustvari iz ponudbe",
      tabs: {
        overview: "Pregled",
        contacts: "Kontakti"
      },
      footer: {
        add_button: "Dodaj pogodbo",
        save_button: "Shrani",
        visible: {
          tooltip: {
            true: "Pogodba ne bo vidna naročniku v portalu.",
            false: "Pogodba bo ponovno vidna naročniku v portalu."
          },
          button: {
            true: "Preklic pogodbe",
            false: "Razveljavi preklic"
          }
        },
        notify: {
          text: "Obvesti",
          prompt: {
            title: "Obvesti vodjo zaposlovanja in podpisnika",
            message: "Vodja zaposlovanja in podpisnik bosta preko maila obveščena, da so v pogodbi na voljo posodobitve. Ste prepričani, da želite poslati mail?",
            confirm: "Pošlji",
            cancel: "Prekliči"
          }
        }
      },
      overview: {
        title: "Naziv",
        company: "Podjetje",
        work_field: "Delovno mesto / storitev",
        service: "Tip storitve",
        candidates_count: "Število kandidatov",
        warranty: "Garancijsko obdobje (št. mesecev)",
        payment_type: "Način obračunavanja honorarja",
        payment_deadline: "Rok plačila (št. dni)",
        execution_deadline: "Rok izvedbe (št. dni)",
        fee: {
          fix: "Fiksni znesek v EUR",
          percent: "% bruto plače"
        },
        fee_lump: "Pavšalni znesek ob podpisu",
        fee_interim: "Vmesni honorar",
        fee_final: "Končni honorar",
        eur: " (EUR)",
        responsible: "Odgovorna oseba Competo",
        hiring_manager: "Vodja zaposlovanja naročnik",
        signer: "Podpisnik naročnik",
        user_not_found: "Oseba ne obstaja",
        company_not_found: "Podjetje ne obstaja",
        salary: "Honorar",
        installment: "Obroki",
        invoice_email: "Email za pošiljanje faktur",
        invoice_number: "Št. naročilnice na fakturi",
        invoice_note: "Dodatna opomba na fakturi",
        sales_consultant: "Prodajni svetovalec",
        type: "Področje",
        umbrella: "Krovna pogodba",
        expiration_date: "Datum trajanja",
        from_offer_popup: {
          title: "Ustvari pogodbo iz ponudbe",
          save_button: "Shrani",
          fields: {
            title: {
              label: "Naziv pogodbe"
            },
            work_field: {
              label: "Delovno mesto",
              placeholder: "Vpišite delovno mesto"
            },
            candidates_count: {
              label: "Število kandidatov"
            },
            warranty: {
              label: "Garancijsko obdobje"
            },
            salary: {
              label: "Honorar"
            },
            offer: {
              label: "Ponudba",
              search: {
                not_found: "Ponudba ne obstaja"
              }
            },
            company: {
              label: "Podjetje"
            }
          }
        }
      },
      contacts: {
        contacts_popup: {
          phone: "Telefonska številka",
          email: "Elektronski naslov",
          save_button: "Shrani",
          type: "Tip",
          label: "Ime in priimek",
          value: "Vrednost",
          contacts: "Kontakti",
          empty_list: "Podjetje nima kontaktov."
        },
        edit_button: "Uredi kontakte",
        select_button: "Izberi kontakte",
        phone: "Telefonska številka",
        email: "Elektronski naslov",
        save_button: "Shrani",
        type: "Tip",
        label: "Ime in priimek",
        value: "Vrednost"
      },
      sidebar: {
        activity: "Aktivnost",
        create: " je ustvaril pogodbo ",
        status_change: " je spremenil status v ",
        signature: " je podpisal pogodbo",
        upload: " je naložil PDF pogodbe",
        approval: " je odobril spremembo statusa v ",
        edit_document: "Uredi Word",
        status_select: "Status pogodbe",
        file_select: "Prikazana datoteka",
        buttons: {
          download_word: "Prenesi Word datoteko",
          upload_pdf: "Naloži PDF datoteko",
          download_pdf: "Prenesi PDF datoteko"
        },
        pdf_doesnt_exist: "Naloži PDF",
        prompts: {
          delete_word: {
            title: "Izbris Word datoteke",
            message: "Word datoteka bo izbrisana in je ne bo možno obnoviti. V naslednjem koraku bo omogočeno ponovno generiranje pogodbe. Ali ste prepričani, da želite izbrisati Word datoteko?",
            confirm: "Izbriši",
            cancel: "Prekliči"
          },
          edit_file: {
            title: "Urejanje Word datoteke",
            message1: "Datoteka bo odprta v programu Microsoft Word, kjer jo lahko urejate. Ob zaključku urejanja je datoteko potrebno shraniti. Spremembe bodo samodejno vidne v aplikaciji Digi.",
            message2: "Ob odprtju datoteke vas bo Microsoft Word vprašal za prijavne podatke. Vpišite podatke, ki so navedeni spodaj.",
            username: "Uporabniško ime",
            password: "Geslo",
            confirm: "Uredi datoteko",
            cancel: "Prekliči"
          }
        },
        delete_word: "Izbriši Word datoteko",
        notify_error: "Prišlo je do napake."
      },
      preview: {
        to_coordination: "Premik v Usklajevanje",
        to_signing: "Premik v Podpisovanje",
        to_signed: "Premik v Podpisana",
        generate_contract: "Generiraj pogodbo",
        document_doesnt_exist: "Dokument ne obstaja",
        preparing_document: "Pripravljamo vaš dokument",
        generate_contract_notify: "Dokument je shranjen v aplikaciji"
      },
      notify_company_employee: {
        title: "Izberite uporabnike",
        description: "Izbrani uporabniki bodo preko maila obveščeni, da so v pogodbi na voljo posodobitve. Izberite uporabnike in kliknite na gumb pošlji.",
        instructions: "V kolikor sta na pogodbo dodana Podpisnik in Vodja zaposlovanja, bosta prikazana na vrhu tabele. Preostali uporabniki na seznamu so HR Managerju v podjetju, za katerega je pogodba narejena.",
        hiring_manager: "Vodja zaposlovanja",
        signer: "Podpisnik",
        search: "Iskanje ...",
        tag: "Število uporabnikov: ",
        table: {
          name: "Ime in priimek"
        },
        cancel_button: "Prekliči",
        confirm_button: "Pošlji"
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbris pogodbe {title}",
      message: "Ste prepričani da želite izbrisati pogodbo {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili pogodbo {title}",
    updated: "Uspešno ste posodobili pogodbo {title}",
    deleted: "Pogodba {title} je bila izbrisana",
    not_found: "Pogodba ni bila najdena"
  }
}
