<template>
    <component
        :is="link.route === 'chat' ? 'button' : 'router-link'"
        :title="t(`views.${link.route}`)"
        :to="`/${link.route}`"
        :class="[{'navigation__indicator__wrapper-chat': link.route === 'chat' && collapsed && indicators.chat}]"
        draggable="true"
        @click="onClick()"
    >
        <div>
            <UikIcon :icon="['fad', link.icon]"/>
            <span>{{ t(`views.${link.route}`) }}</span>
        </div>

        <div>
            <div
                v-if="link.route === 'chat' && indicators.chat"
                class="navigation__indicator-chat"
            >
                {{  indicators.chat }}
            </div>
        </div>
    </component>
</template>

<script>
export default {
  langKey: "Global.navigation",
  props: {
    link: { type: Object, default: () => { return {} } },
    collapsed: { type: Boolean, default: false },
    indicators: { type: Object, default: () => { return {} } }
  },
  methods: {
    onClick () {
      if (this.link.route !== "chat") return
      this.$emit("openChatPopup")
    }
  }
}
</script>
