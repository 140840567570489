<template>
    <nav
        class="new-navigation"
        :class="{
            'new-navigation--open': openNav,
            'new-navigation--collapsed': collapsed,
        }"
    >
        <div class="navigation__container">
            <div class="navigation__top">
                <div class="navigation__logo">
                    <router-link to="/">
                        <img src="/img/COMPETO_LOGO_2024.svg" alt="Competo Logo"/>
                        <span>DIGI</span>
                    </router-link>
                    <button class="navigation__toggle-navigation-button"  @click="toggleCollapse()">
                        <div v-for="n in 3" :key="n" />
                    </button>
                </div>
                <!-- <div class="navigation__user">
                    <UikAvatar
                        :name="user.name"
                        :text-bottom="user.position_title ? user.position_title : user.email"
                        :imageUrl="user.avatar_url ? user.avatar_url : ''"
                        imageOnly
                        @click.native="$router.push('/settings'); openNav = false;"
                    />
                    <h2>{{ user.name ? user.name : "Povezovanje ..." }}</h2>
                    <h3>{{ user.position_title ? user.position_title : user.email }}</h3>
                </div> -->
            </div>
            <div class="navigation__scroll-wrapper">
                <div class="navigation__views">
                    <div
                        v-for="(link, index) in getLinks"
                        :key="`link-${index}`"
                        style="width: 100%;"
                    >
                        <NavLink
                            v-if="link.type === 'link'"
                            :link="link"
                            :collapsed="collapsed"
                            :indicators="indicators"
                            @openChatPopup="$emit('openChatPopup')"
                            @click.native="openNav = false"
                        />
                        <NavGroup
                            v-else-if="link.type === 'group'"
                            :link="link"
                            :collapsed="collapsed"
                            :indicators="indicators"
                            @openChatPopup="$emit('openChatPopup')"
                            @closeNavigation="openNav = false"
                        />
                    </div>
                </div>
                <div class="navigation__bottom">
                    <router-link
                        to="/settings"
                        :title="collapsed ? t('views.settings') : ''"
                        @click.native="openNav = false"
                    >
                        <UikIcon :icon="['fad', 'cog']" />
                        <span>{{ t('views.settings') }}</span>
                    </router-link>
                    <button
                        :title="collapsed ? t('views.logout') : ''"
                        @click="
                            openNav = false;
                            $api.Auth.logout();
                        "
                    >
                        <UikIcon :icon="['fad', 'sign-out-alt']" />
                        <span>{{ t('views.logout') }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="navigation__mobile">
            <div class="navigation__mobile-btn-placeholder" />
            <router-link to="/">
                <img src="/img/COMPETO_LOGO_2024.svg" alt="Competo Logo"/>
                <span>DIGI</span>
            </router-link>
            <UikAvatar
                :name="user.name"
                :text-bottom="user.position_title ? user.position_title : user.email"
                :imageUrl="user.avatar_url ? user.avatar_url : ''"
                imageOnly
                primary
                @click.native="$router.push('/settings')"
            />
        </div>
        <button class="navigation__toggleNav" @click="openNav = !openNav">
            <div v-for="n in 3" :key="n" />
        </button>
    </nav>
</template>

<script>
import Chat from "@/Modules/Chat/Resources/Chat"
import NavLink from "./NavLink.vue"
import NavGroup from "./NavGroup.vue"

export default {
  langKey: "Global.navigation",
  resources: { Chat },
  components: { NavLink, NavGroup },
  links: [
    {
      type: "link",
      route: "tasks",
      icon: "tasks",
      permissions: []
    },
    {
      type: "group",
      title: "search_and_selection",
      children: [
        {
          type: "link",
          route: "projects",
          icon: "rocket",
          permissions: ["Project"]
        },
        {
          type: "link",
          route: "candidates",
          icon: "user-friends",
          permissions: ["Candidate"]
        },
        {
          type: "link",
          route: "chat",
          icon: "comments",
          permissions: ["Chat"]
        }
      ]
    },
    {

      type: "group",
      title: "rosl",
      children: [
        {
          type: "link",
          route: "testings",
          icon: "vial",
          permissions: ["Testing"]
        },
        {
          type: "link",
          route: "assessment-center",
          icon: "user-chart",
          permissions: ["RatingCenter"]
        },
        {
          type: "link",
          route: "measures",
          icon: "tachometer",
          permissions: ["Measure"]
        },
        {
          type: "link",
          route: "competences",
          icon: "award",
          permissions: ["Competence"]
        },
        {
          type: "link",
          route: "focus-groups",
          icon: "clipboard-user",
          permissions: ["FocusGroup"]
        },
        {
          type: "link",
          route: "questionnaires",
          icon: "clipboard-list",
          permissions: ["Questionnaire"]
        }
      ]
    },
    {

      type: "group",
      title: "crm",
      children: [
        {
          type: "link",
          route: "companies",
          icon: "city",
          permissions: ["Company"]
        },
        {
          type: "link",
          route: "employees",
          icon: "users",
          permissions: ["CompanyEmployee"]
        },
        {
          type: "link",
          route: "offers",
          icon: "file-signature",
          permissions: ["Offer"]
        },
        {
          type: "link",
          route: "contracts",
          icon: "file-contract",
          permissions: ["Contract"]
        }
      ]
    },
    {
      type: "link",
      route: "feedback",
      icon: "chart-user",
      permissions: ["Feedback"]
    },
    {
      type: "link",
      route: "finances",
      icon: "euro-sign",
      permissions: ["FinanceAccounting"]
    },
    {
      type: "link",
      route: "consents",
      icon: "shield-check",
      permissions: ["Consent"]
    },
    {
      type: "link",
      route: "administration",
      icon: "user-cog",
      permissions: ["Employee", "User", "Role", "CodelistData", "DocumentTemplate", "NotificationTemplate", "Job"]
    }
    // {
    //   type: "link",
    //   route: "informative-offers",
    //   icon: "file-circle-question",
    //   permissions: ["InformativeOffer"]
    // },
    // {
    //   type: "link",
    //   route: "analytics",
    //   icon: "chart-simple",
    //   permissions: ["QuestionnaireAnalytics", "Company"]
    // }
  ],
  data () {
    return {
      openNav: false,
      collapsed: false,

      indicators: {
        chat: 0
      }
    }
  },
  computed: {
    user () {
      return this.$api.Auth && this.$api.Auth.state && this.$api.Auth.state.user
        ? this.$api.Auth.state.user
        : {}
    },
    getLinks () {
      return this.$options.links.filter(link => {
        if (link.type === "link") {
          let show = false
          if (!link.permissions || link.permissions.length === 0) return true

          link.permissions.forEach(permission => {
            if (this.userCan(permission + "/view")) {
              show = true
            }
          })
          return show
        } else if (link.type === "group") {
          if (link.children?.length > 0) {
            return link.children.some(c => {
              let show = false
              if (!c.permissions || c.permissions.length === 0) return true

              c.permissions.forEach(permission => {
                if (this.userCan(permission + "/view")) {
                  show = true
                }
              })
              return show
            })
          }
        }
      })
    }
  },
  created () {
    this.collapsed = localStorage.menuCollapsed === "true"

    this.getTotalUnread()

    this.socketListener()
  },
  beforeDestroy () {
    this.indicators.chat = 0

    window.Echo.channel("user").stopListening(".chat-indicator")
  },
  methods: {
    toggleCollapse () {
      this.collapsed = !this.collapsed
      localStorage.menuCollapsed = this.collapsed
    },
    async getTotalUnread () {
      if (!this.userCan("Chat/view")) {
        return
      }

      let res = await this.$api.Chat.getTotalUnread()

      if (res.status === 200) {
        let number = res.data && res.data.count ? res.data.count : 0

        if (number && number > 99) {
          number = "99+"
        }

        this.indicators.chat = number
      }
    },
    socketListener () {
      if (!window.Echo) return

      window.Echo.channel("user").listen(".chat-indicator", event => {
        this.getTotalUnread()
      })
    }
  }
}
</script>

<style lang="scss">
.new-navigation {
  $sectionBorderColor: #3b3b3f;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  max-width: 280px;
  transition: left 0.5s, max-width 0.3s ease-out;
  .navigation__container {
    width: 100%;
    height: 100%;
    background: transparent;
    border-right: solid 1px rgba(#e2e5ed, 0.85);
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    .navigation__top {
      width: 100%;
      @media only #{$mediaTablet} {
        padding-right: 0;
        border-bottom: $borderDefault;
      }
      .navigation__logo {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 25px 0 20px;
        height: $headerHeightDefault;
        position: relative;
        @media only #{$mediaTablet} {
          height: 56px;
          .navigation__toggle-navigation-button {
            display: none;
          }
        }

        a {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 5px;
          cursor: pointer;
          img {
            width: 118px;
          }
          span {
            color: rgba($textBase, 0.8);
            text-transform: uppercase;
            font-size: 12px;
            transform: translateY(-3px);
            margin-left: 3px;
            letter-spacing: 0.2px;
            font-weight: 500;
          }
        }

        .navigation__version {
          position: absolute;
          top: 50px;
          font-size: 10px;
          font-weight: bold;
        }

        .navigation__toggleCollapse {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          width: 34px;
          height: 34px;
          background: transparent;
          outline: none;
          border: none;
          transition: all 0.2s;
          border-radius: 50%;
          padding-right: 1px;
          position: absolute;
          right: 17px;
          color: $textSecondary;
          &:hover {
            cursor: pointer;
            color: $primary;
          }
          &:active {
            transform: scale(0.9);
          }
          svg {
            height: 22px;
            font-size: 22px;
          }
        }
      }
      .navigation__user {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding: 8px 25px 23px 20px;
        transition: width 0.14s;
        width: 100%;
        .uik-avatar__wrapper {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .uik-avatar__avatarWrapper {
            pointer-events: none;
            width: 66px;
            height: 66px;
            border-radius: 50%;
            min-width: 66px;
            padding: 0;
            transition: all 0.2s;
            box-shadow: 0 3px 15px -5px rgba(black, 0.4);
            .uik-avatar__avatar {
              border-radius: 50%;
            }
            .uik-avatar__avatarPlaceholder {
              background: $sectionBorderColor;
              border-color: $sectionBorderColor;
              border-radius: 50%;
              color: $textLight;
              font-size: 20px;
              line-height: 1;
              font-weight: 600;
            }
          }
          .uik-avatar__info {
            text-align: center;
            margin-left: 0;
            margin-top: 15px;
            width: 100%;
            .uik-avatar__name {
              color: white;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.2;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
              display: block;
            }
            .uik-avatar__textBottom {
              color: $textSecondary;
              font-size: 12px;
              font-weight: 500;
              line-height: 1.2;
              margin-top: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
            }
          }
          &:hover {
            .uik-avatar__avatarWrapper {
              transform: scale(1.05);
              box-shadow: 0 5px 20px -5px rgba(black, 0.5);
            }
          }
          &:active {
            .uik-avatar__avatarWrapper {
              box-shadow: 0 2px 5px 0 rgba(black, 0.25);
              transform: scale(0.95);
            }
          }
        }
        h2 {
          margin-top: 15px;
          width: 100%;
          text-align: center;
          color: white;
          font-size: 15px;
          font-weight: 500;
          line-height: 1.4;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          display: block;
        }
        h3 {
          color: $textSecondary;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.4;
          margin-top: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          width: 100%;
          text-align: center;
        }
      }
    }
    .navigation__scroll-wrapper {
      overflow: auto;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding: 0;
      flex-grow: 1;
      &::-webkit-scrollbar-track {
        display: initial;
      }
      &::-webkit-scrollbar {
        display: initial;
        border-left: none;
      }
      &::-webkit-scrollbar-thumb {
        border: none;
      }

      &:hover {
        &::-webkit-scrollbar {
          display: initial;
        }
      }
    }
    .navigation__views,
    .navigation__bottom {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      a,
      button {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 16px 25px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        width: 100%;
        border-radius: 0;
        border: none;
        outline: none;
        background: transparent;
        transition: background-color 0.1s, transform 0.1s;
        border-left: solid 0px transparent;
        position: relative;
        &::before {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          content: "";
          background: linear-gradient(90deg, rgba($primary, 0.95), rgba($secondary, 0.85));
          opacity: 0;
          transition: all 0.15s;
        }
        > * {
          position: relative;
          z-index: 1;
        }
        svg {
          font-size: 16px;
          width: 18px;
          transition: color 0.1s;
          color: rgba($textBase, 0.75);
        }
        span {
          color: $textMain;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          margin-left: 12px;
          transition: all 0.1s;
        }
        &:not(.router-link-active) {
          &:hover {
            transition: all 0.05s;
            cursor: pointer;
            background: #f0e8f1;
            background-clip: unset;
            -webkit-text-fill-color: unset;
            color: unset;
            svg {
              color: rgba($primary, 0.8);
              transition: all 0.05s;
            }
            &::before {
              opacity: 0.1;
            }
            span {
              background: $gradient;
              background-clip: text;
              -webkit-text-fill-color: transparent;

              transition: all 0.05s;
            }
          }
          &:active {
            transition: all 0.1s;
            background: #eddbe3;
            &::before {
              opacity: 0.2;
            }
          }
        }
        &.router-link-active,
        &.router-link-exact-active {
          background: linear-gradient(90deg, rgba($primary, 0.95), rgba($secondary, 0.85));
          svg {
            color: white;
            animation: navigation-active-icon 0.2s;
            @keyframes navigation-active-icon {
              from {
                opacity: 0.5;
                transform: scale(0.6);
              }
            }
          }
          span {
            color: white;
          }
        }
        & + a,
        & + button {
          // margin-top: 1px;
        }
      }
    }
    .navigation__views {
      flex-grow: 1;
      // padding-bottom: 3px;
      div {
        a,
        button {
          display: flex;
          justify-content: space-between;
          div {
            display: flex;
          }
          .navigation__indicator-chat {
            display: flex;
            justify-content: center;
            text-align: center;
            color: white;
            background: $error;
            min-width: 18px;
            line-height: 18px;
            padding: 0px 2px;
            border-radius: 99px;
            font-size: 10px;
          }
        }
      }
    }
  }
  .navigation__mobile {
    display: none;
    position: fixed;
    z-index: 9;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    background: white;
    height: 56px;
    padding: 0 8px;
    border-bottom: $borderDefault;
    .navigation__mobile-btn-placeholder {
      width: 40px;
      min-width: 40px;
      height: 20px;
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
    }
    .navigation__mobile-logo {
      height: 18px;
      fill: black;
    }
    a {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 5px;
      cursor: pointer;
      img {
        width: 118px;
      }
      span {
        color: rgba($textBase, 0.8);
        text-transform: uppercase;
        font-size: 12px;
        transform: translateY(-3px);
        margin-left: 3px;
        letter-spacing: 0.2px;
        font-weight: 500;
      }
    }
    .uik-avatar__wrapper {
      padding: 4px;
      cursor: pointer;
      .uik-avatar__avatarWrapper {
        width: 33px;
        height: 33px;
        .uik-avatar__avatarPlaceholder {
          background: lighten($primary, 2%);
          border: none;
          color: white;
          font-size: 13px;
          line-height: 1;
          font-weight: 600;
        }
      }
    }
  }
  .navigation__toggleNav {
    display: none;
    position: fixed;
    left: 8px;
    width: 40px;
    min-width: 40px;
    background: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 10px;
    transition: all 0.25s;
    top: 12px;
    z-index: 11;
    div {
      position: relative;
      width: 100%;
      height: 2px;
      background: $primary;
      border-radius: 999px;
      flex-grow: 1;
      transition: all 0.25s;
    }
    div:nth-child(1) {
      margin-top: 0;
      width: 70%;
    }
    div:nth-child(2) {
      margin-top: 3px;
    }
    div:nth-child(3) {
      margin-top: 3px;
      float: right;
      width: 70%;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .navigation__toggle-navigation-button {
    display: block;
    width: 40px;
    min-width: 40px;
    background: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 10px;
    transition: all 0.25s;
    div {
      position: relative;
      width: 100%;
      height: 2px;
      background: rgba($textBase, 0.7);
      border-radius: 999px;
      flex-grow: 1;
      transition: all 0.25s;
    }
    div:nth-child(1) {
      margin-top: 0;
      width: 70%;
    }
    div:nth-child(2) {
      margin-top: 3px;
    }
    div:nth-child(3) {
      margin-top: 3px;
      float: right;
      width: 70%;
    }
    &:hover {
      cursor: pointer;
      div {
        background: $primary;
        &:nth-child(1) {
          width: 60%;
          margin-left: 40%;
          transform: rotate(-45deg) translateY(2px);
        }
        &:nth-child(2) {
          width: 0%;
          margin-left: 100%;
          transition-duration: 0.15s;
        }
        &:nth-child(3) {
          width: 60%;
          transform: rotate(45deg) translateY(-2px);
        }
      }
    }
    &:active {
      div {
        background: rgba($primary, 0.65 ) !important;
      }
    }
  }
  & + * {
    margin-left: 280px;
    transition: all 0.3s ease-out;
    @media only #{$mediaTablet} {
      margin-left: 0;
      margin-top: 56px;
    }
  }
  @media only #{$mediaTablet} {
    left: -100%;
    max-width: unset;
    .navigation__mobile {
      display: flex;
    }
    .navigation__toggleNav {
      display: block;
    }
    &--open {
      left: 0;
      .navigation__toggleNav {
        div {
          // background: white;
        }
        div:nth-child(1) {
          margin-top: 0;
          width: 100%;
          transform: translateX(5px) rotate(45deg) translateY(7px);
        }
        div:nth-child(2) {
          width: 0;
          margin-left: 50%;
        }
        div:nth-child(3) {
          transform: translateX(5px) rotate(-45deg) translateY(-7px);
          width: 100%;
        }
        &:hover {
          cursor: pointer;
          div {
            &:nth-child(1) {
              margin-left: 0;
              width: 100%;
            }
            &:nth-child(3) {
              margin-right: 0;
              width: 100%;
            }
          }
        }
      }
    }
    .navigation__container {
      position: relative;
      z-index: 10;
      background: white;
      .navigation__top {
        .navigation__logo {
          justify-content: center;
          .navigation__toggleCollapse {
            display: none;
          }
        }
      }
    }
  }
  // $mediaTablet
  @media only screen and (min-width: 1024px) {
    &--collapsed {
      max-width: 60px;
      & + * {
        margin-left: 60px;
        @media only #{$mediaTablet} {
          margin-left: 0;
        }
      }
      .navigation__container {
        .navigation__top {
          padding-right: 0;
          .navigation__logo {
            background: transparent;
            border-bottom: $borderDefault;
            justify-content: center;
            height: 40px;
            a {
              display: none;
            }
            .navigation__toggleCollapse {
              right: 13px;
              transform: scaleX(-1);
              &:hover {
                background: transparent;
              }
              &:active {
                transform: scaleX(-0.9) scaleY(0.9);
              }
            }
            .navigation__toggle-navigation-button {
              div {
                background: rgba($textMain, 0.85);
                &:nth-child(2) {
                  margin-top: 3px;
                }
                &:nth-child(3) {
                  margin-top: 3px;
                }
              }
              &:hover {
                cursor: pointer;
                transform: translateX(-2px);
                div {
                  background: $primary;
                  &:nth-child(1) {
                    width: 60%;
                    margin-left: 40%;
                    transform: rotate(45deg) translateY(2px);
                  }
                  &:nth-child(2) {
                    width: 0%;
                    margin-left: 0;
                    margin-right: 100% !important;
                    transition-duration: 0.15s;
                  }
                  &:nth-child(3) {
                    width: 60%;
                    transform: rotate(-45deg) translateY(-2px);
                  }
                }
              }
            }
          }
          .navigation__user {
            padding: 15px 0 7px 0;
            width: 60px;
            transition: width 0.35s;
            .uik-avatar__wrapper {
              .uik-avatar__avatarWrapper {
                width: 46px;
                height: 46px;
                min-width: 46px;
                padding: 2px;
                background: transparent;
                box-shadow: none;
                .uik-avatar__avatar,
                .uik-avatar__avatarPlaceholder {
                  border-width: 1px;
                }
                .uik-avatar__avatarPlaceholder {
                  font-size: 14px;
                }
              }
              &:hover {
                .uik-avatar__avatarWrapper {
                  box-shadow: none;
                  transform: none;
                  background: lighten($primary, 2%);
                }
              }
              &:active {
                .uik-avatar__avatarWrapper {
                  box-shadow: none;
                  background: rgba(lighten($primary, 2%), 0.5);
                }
              }
            }
            h2,
            h3 {
              display: none;
            }
          }
        }
        .navigation__scroll-wrapper {
          padding: 0;
          &:hover {
            .navigation__bottom {
              .navigation__group-title,
              a,
              button {
                // padding-left: 16px;
              }
            }
          }
        }
        .navigation__views,
        .navigation__bottom {
          padding: 0;
          .navigation__group-title,
          a,
          button {
            padding: 0;
            height: 40px;
            border-radius: 4px;
            width: 100%;
            padding: 0 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              display: none;
            }
            span {
              display: none;
            }
            svg {
              font-size: 19px;
              width: 19px;
            }
            & + a,
            & + button {
              // margin-top: 2px;
            }
            &.router-link-active,
            &.router-link-exact-active {
              background: $primary;
            }
          }
          .navigation__group {
            width: 100%;
          }
          .navigation__group-title {
            justify-content: flex-start;
          }
          .navigation__group-subitems {
            a,
            button {
              padding: 16px 20px 16px 28px !important;

              &.navigation__indicator__wrapper-chat {
                padding: 0 8px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
