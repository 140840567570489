<template>
    <div class="navigation__group">
        <div
            class="navigation__group-title"
            :title="t(`views.${link.title}`)"
            draggable="true"
            @click="toggleOpened()"
        >
            <UikIcon :icon="['fad', opened ? 'folder-open' : 'folder']" />
            <span>{{ t(`views.${link.title}`) }}</span>
        </div>
        <div v-if="opened" class="navigation__group-subitems">
            <NavLink
                v-for="(sublink, index) in visibleLinks"
                :key="`sublink-${index}`"
                :link="sublink"
                :collapsed="collapsed"
                :indicators="indicators"
                @openChatPopup="$emit('openChatPopup')"
                @click.native="$emit('closeNavigation')"
            />
        </div>
    </div>
</template>

<script>
import NavLink from "./NavLink.vue"

export default {
  langKey: "Global.navigation",
  components: { NavLink },
  props: {
    link: { type: Object, default: () => { return {} } },
    collapsed: { type: Boolean, default: false },
    indicators: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      opened: false
    }
  },
  computed: {
    visibleLinks () {
      return this.link.children.filter(sublink => {
        let show = true
        if (sublink.permissions?.length > 0) {
          show = false
          sublink.permissions.forEach(permission => {
            if (this.userCan(permission + "/view")) {
              show = true
            }
          })
        }
        return show
      })
    }
  },
  mounted () {
    const savedState = Boolean(localStorage.getItem(this.link.title + "-opened"))
    if (savedState !== null) {
      this.opened = savedState
    }
  },
  methods: {
    toggleOpened () {
      this.opened = !this.opened
      localStorage.setItem(this.link.title + "-opened", this.opened ? "true" : "")
    }
  }
}
</script>

<style lang="scss">
.navigation__group {
  display: flex;
  flex-flow: column nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  color: $textMain;
  user-select: none;

  .navigation__group-title,
  .navigation__group-subitems a {
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      content: "";
      background: linear-gradient(90deg, rgba($primary, 0.95), rgba($secondary, 0.85));
      opacity: 0;
      transition: all 0.15s;
    }
    > * {
      position: relative;
      z-index: 1;
    }
    &:not(.router-link-active) {
      &:hover {
        transition: all 0.05s;
        cursor: pointer;
        background: #f0e8f1;
        background-clip: unset;
        -webkit-text-fill-color: unset;
        color: unset;
        svg {
          color: rgba($primary, 0.8);
          transition: all 0.05s;
        }
        &::before {
          opacity: 0.1;
        }
        span {
          background: $gradient;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: all 0.05s;
        }
      }
      &:active {
        transition: all 0.1s;
        background: #eddbe3;
        &::before {
          opacity: 0.2;
        }
      }
    }
    & + a {
      margin-top: 0 !important;
    }
  }

  .navigation__group-title {
    display: flex;
    padding: 16px 25px;
    svg {
      font-size: 16px;
      width: 18px;
    }

    span {
      margin-left: 12px;
    }
  }

  .navigation__group-subitems {
    display: flex;
    flex-flow: column nowrap;
    a,
    button {
      padding: 16px 50px !important;
    }
  }
}
</style>
